<template>
  <b-modal
    id="modal-review-task"
    ref="refModalReview"
    title="Nghiệm thu công việc"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-alert
        show
        variant="primary"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">Bạn đang tiến hành nghiệm thu công việc: <strong>{{ itemLocal.title }}</strong>.</span>
        </div>
      </b-alert>
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="6">
            <b-card
              no-body
              :class="`shadow-none border ${itemLocal.status ? 'bg-light-primary' : 'bg-light-secondary'} `"
              @click="setStatus(true)"
            >
              <b-card-header>
                <b-card-title>Đạt yêu cầu</b-card-title>
                <b-form-radio
                  v-model="itemLocal.status"
                  name="status"
                  :value="true"
                  class="custom-control-primary"
                />
              </b-card-header>
            </b-card>

          </b-col>
          <b-col>
            <b-card
              no-body
              :class="`shadow-none border ${!itemLocal.status ? 'bg-light-danger' : 'bg-light-secondary'} `"
              @click="setStatus(false)"
            >
              <b-card-header>
                <b-card-title>Không đạt</b-card-title>
                <b-form-radio
                  v-model="itemLocal.status"
                  name="status"
                  :value="false"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
        </b-row>

        <!-- Description -->
        <validation-provider
          v-if="itemLocal.status === false"
          #default="validationContext"
          name="Mô tả"
          rules="required"
        >
          <b-form-group
            label="Mô tả lý do chưa đạt"
          >
            <b-form-textarea
              v-model="itemLocal.reviewNote"
              :state="getValidationState(validationContext)"
              rows="3"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- <media-view
          v-if="itemLocal.status === false"
          v-model="itemLocal.reviewAttachments"
          label="Đính kèm"
          :initial-value="itemLocal.reviewAttachments"
        /> -->

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BForm,
  BFormInvalidFeedback,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormRadio,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import MediaView from '@/views/components/media/MediaView.vue';
import useReviewTaskModal from './useReviewTaskModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BForm,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
    MediaView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalReview,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      setStatus,
    } = useReviewTaskModal(props, emit, refFormObserver);

    return {
      refModalReview,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      setStatus,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
