import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import taskStoreModule from '../taskStoreModule';

export default function useReviewTaskModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'task';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, taskStoreModule);
  }

  const toastification = toast();

  const refModalReview = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);

  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();

    formData.append('id', itemLocal.value.id);
    formData.append('status', itemLocal.value.status ? '4' : '5');
    formData.append('reviewNote', itemLocal.value.reviewNote);
    // eslint-disable-next-line no-restricted-syntax
    // for (const attachment of itemLocal.value.reviewAttachments) {
    //   if (attachment.file) {
    //     formData.append('reviewAttachments', attachment.file);
    //   }
    // }

    isSubmitting.value = true;

    store
      .dispatch('task/updateTaskStatus', formData)
      .then(response => { emit('on-item-updated', response.data); isSubmitting.value = false; })
      .then(() => {
        refModalReview.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const setStatus = newStatus => {
    itemLocal.value.status = newStatus;
  };

  return {
    refModalReview,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    setStatus,
  };
}
