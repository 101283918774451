<template>
  <b-modal
    id="modal-task-detail"
    ref="refModalTaskDetail"
    title="Thông tin công việc"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">

      <b-list-group class="">

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Tên công việc:
            </b-col>
            <b-col class=" ">
              {{ itemLocal.title }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Nội dung công việc:
            </b-col>
            <b-col class="">
              {{ itemLocal.description }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.attachments && itemLocal.attachments.filter(object => !object.isResultAttachment).length > 0">
          <b-row>
            <b-col class="font-weight-bolder">
              Đính kèm:
            </b-col>

          </b-row>
          <media-view
            :initial-value="itemLocal.attachments.filter(object => !object.isResultAttachment)"
            :preview-mode="true"
          />
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.type">
          <b-row>
            <b-col class="font-weight-bolder">
              Loại công việc:
            </b-col>
            <b-col class="">
              {{ itemLocal.type.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày tạo:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.createdAt, 'DD-MM-YYYY HH:mm') }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.creator">
          <b-row>
            <b-col class="font-weight-bolder">
              Người tạo:
            </b-col>
            <b-col class="">
              {{ itemLocal.creator.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              Tòa nhà:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.room">
          <b-row>
            <b-col class="font-weight-bolder">
              Phòng:
            </b-col>
            <b-col class="">
              {{ itemLocal.room.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Hạn hoàn thành:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.dueDate) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.performers">
          <b-row>
            <b-col class="font-weight-bolder">
              Người thực hiện:
            </b-col>
            <b-col class="">
              {{ resolvePerformers(itemLocal.performers) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Trạng thái:
            </b-col>
            <b-col class="">
              <b-badge
                pill
                :variant="itemLocal.status.variant"
              >
                {{ itemLocal.status.title }}
              </b-badge>
              <b-badge
                v-if="itemLocal.isOverDue"
                class="ml-50"
                pill
                variant="light-danger"
              >
                Quá hạn
              </b-badge>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="parseInt(itemLocal.status.value, 10) >= 3">

          <b-row>
            <b-col class="font-weight-bolder">
              Hoàn thành lúc:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.finishDate, 'HH:mm DD-MM-YYYY') }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="parseInt(itemLocal.status.value, 10) >= 3">
          <b-row>
            <b-col class="font-weight-bolder">
              Kết quả xử lý:
            </b-col>
            <b-col class="">
              {{ itemLocal.finishNote }}
            </b-col>
          </b-row>
          <b-row>
            <b-col class="font-weight-bolder">
              Hoàn thành lúc:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.finishDate, 'HH:mm DD-MM-YYYY') }}
            </b-col>
          </b-row>
          <media-view
            v-if="itemLocal.attachments && itemLocal.attachments.filter(object => object.isResultAttachment).length > 0"
            :initial-value="itemLocal.attachments.filter(object => object.isResultAttachment)"
            :preview-mode="true"
          />
        </b-list-group-item>

        <b-list-group-item v-if="itemLocal.status.value === '5'">

          <b-row>
            <b-col class="font-weight-bolder">
              Lý do không nghiệm thu:
            </b-col>
            <b-col class="">
              {{ itemLocal.reviewNote }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>

      <!-- comment box -->
      <b-form-group class="mt-2">
        <b-form-textarea
          v-model="comment"
          rows="3"
          placeholder="Thêm cập nhật, trao đổi"
        />
      </b-form-group>
      <!--/ comment box -->

      <b-button
        size="sm"
        variant="primary"
        :disabled="isSubmitting"
        @click="submitComment"
      >
        Bình luận
      </b-button>

      <!-- comments -->
      <div
        v-for="(comment,ind) in itemLocal.comments.slice().reverse()"
        :key="ind"
        class="d-flex align-items-start mt-2"
      >
        <b-avatar
          :src="comment.user.avatar"
          size="34"
          class="mt-25 mr-75"
        />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              {{ comment.user.name }}
            </h6>
            <!-- <b-link class="text-body">
              <feather-icon
                icon="HeartIcon"
                size="18"
                class="mr-25"
                :class="comment.youLiked ? 'profile-likes' :'profile-icon'"
              />
              <span class="text-muted align-middle">{{ comment.commentsLikes }}</span>
            </b-link> -->
            <small>{{ parseDateToString(comment.created_at, 'DD-MM-YYYY HH:mm') }}</small>
          </div>
          <small>{{ comment.content }}</small>
        </div>
      </div>
      <!--/ comments -->

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BBadge,
  BFormTextarea,
  BButton,
  BFormGroup,
  BAvatar,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import MediaView from '@/views/components/media/MediaView.vue';
import { parseDateToString } from '@/auth/utils';
import useTaskDetailModal from './useTaskDetailModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    MediaView,
    BBadge,
    BFormTextarea,
    BButton,
    BFormGroup,
    BAvatar,
  },
  props: {
    taskId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      resolvePerformers,
      comment,
      isSubmitting,
      submitComment,
    } = useTaskDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      resolvePerformers,
      parseDateToString,
      comment,
      isSubmitting,
      submitComment,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
