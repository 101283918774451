import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import { isEnableBed } from '@/auth/utils';
import taskStoreModule from '../taskStoreModule';

export default function useTaskModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'task';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, taskStoreModule);
  }

  const toastification = toast();

  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);

  const resetModal = () => {
    isSubmitting.value = false;
    resetItemLocal();
  };

  const onDeleteAttachment = id => {
    if (itemLocal.value.deleteAttachmentIds && itemLocal.value.deleteAttachmentIds.length > 0) {
      itemLocal.value.deleteAttachmentIds.push(id);
    } else {
      itemLocal.value.deleteAttachmentIds = [id];
    }
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();

    formData.append('title', itemLocal.value.title);
    formData.append('description', itemLocal.value.description);
    if (moment(itemLocal.value.dueDate, 'DD-MM-YYYY HH:mm', true).isValid()) {
      formData.append('dueDate', itemLocal.value.dueDate);
    } else if (moment(itemLocal.value.dueDate).isValid()) {
      formData.append('dueDate', moment(itemLocal.value.dueDate).format('DD-MM-YYYY HH:mm'));
    }
    if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
      formData.append('apartmentId', itemLocal.value.apartment.id);
    }
    if (itemLocal.value.room && itemLocal.value.room.id > 0) {
      formData.append('roomId', itemLocal.value.room.id);
    }
    if (itemLocal.value.bed && itemLocal.value.bed.id > 0) {
      formData.append('bedId', itemLocal.value.bed.id);
    }
    if (itemLocal.value.assignee && itemLocal.value.assignee.id > 0) {
      formData.append('assigneeId', itemLocal.value.assignee.id);
    }
    if (itemLocal.value.type && itemLocal.value.type.id > 0) {
      formData.append('typeId', itemLocal.value.type.id);
    }
    if (itemLocal.value.priorityObject && itemLocal.value.priorityObject.value) {
      formData.append('priority', itemLocal.value.priorityObject.value);
    } else {
      formData.append('priority', 'medium');
    }
    if (itemLocal.value.performers) {
      // eslint-disable-next-line no-restricted-syntax
      for (const eachPerformer of itemLocal.value.performers) {
        formData.append('performerIds[]', eachPerformer.id);
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const attachment of itemLocal.value.attachments) {
      if (attachment.file) {
        formData.append('attachments', attachment.file);
      }
    }

    if (itemLocal.value.deleteAttachmentIds && itemLocal.value.deleteAttachmentIds.length > 0) {
      formData.append('deleteAttachmentIds', itemLocal.value.deleteAttachmentIds);
    }

    isSubmitting.value = true;
    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('task/updateTask', { data: formData, id: itemLocal.value.id })
        .then(response => { emit('on-item-updated', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('task/addTask', formData)
        .then(response => { emit('on-item-created', response.data); isSubmitting.value = false; })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onDeleteAttachment,
    resolveColWidthIfDisableBed,
  };
}
